<template>
	<div class="hk-card-deck">
		<slot />
	</div>
</template>

<script>
	export default {
		name: 'hk-card-deck',
	}
</script>